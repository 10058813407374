$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; .spoiled {
  cursor: auto;
  transform: translateZ(0); // Safari jitter fix

  .lightbox .meta {
    display: none;
  }

  svg {
    vertical-align: middle;
  }
}

.spoiler-blurred {
  @include unselectable;
  cursor: pointer;
  filter: blur(0.5em);

  a,
  area,
  audio,
  button,
  details,
  embed,
  iframe,
  img.animated,
  input,
  map,
  object,
  option,
  portal,
  select,
  textarea,
  track,
  video,
  .lightbox {
    pointer-events: none;
  }

  img {
    filter: blur(1em);
  }

  .discourse-no-touch & {
    &:hover,
    &:focus {
      filter: blur(0.18em);

      img {
        filter: blur(0.5em);
      }
    }
  }
}
